.dashboard-content-scrape-management  .title {
    font-size: large;
    font-weight: 500;
}

.scraping-card {
    width: 100%;
    border-radius: 0px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

.scraping-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.start-scraping {
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
    margin-right: 10px;
}

.start-scraping:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}

.view-progress-scraping {
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.view-progress-scraping:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
}

.save-grants-button {
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.save-grants-button:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}

.save-grants-button:disabled,
.save-grants-button:disabled:hover {
    background: rgb(186, 186, 186) !important;
    border-color: rgb(186, 186, 186) !important;
    color: white !important;
    cursor: not-allowed;
}
