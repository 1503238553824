.dashboard-sidebar .ant-menu {
    margin-top: 0;
    padding: 15px;
}

.dashboard-sidebar .ant-menu-item {
    color: #44534f !important;
    font-weight: 500;
    font-size: 17pt;
    margin-bottom: 15px;
}

.dashboard-sidebar .anticon {
    font-size: 17pt !important;
}

.dashboard-sidebar .ant-menu-item-selected {
    background-color: white;
    color: #00b080 !important;
}

.dashboard-sidebar .ant-menu-vertical>.ant-menu-item::after,
.dashboard-sidebar .ant-menu-vertical>.ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}