/* Import Font */
@font-face {
  font-family: 'Basique';
  src: url('../../assets/fonts/BasiqueBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Base Styles */
.about-us {
  font-family: 'Basique', sans-serif;
  color: #444;
  text-align: center;
  /* background: linear-gradient(120deg, #e0f7f5, #ffffff); */
  padding: 50px 20px;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

/* Header Section */
.about-us-header {
  margin-bottom: 30px;
}

.about-us-header h1 {
  font-size: 3rem;
  color: #00b080;
  margin-bottom: 10px;
}

.about-us-header p {
  font-size: 1.2rem;
  color: #555;
  font-style: italic;
  margin: 0;
}

/* Content Section */
.context-container {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px 30px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.context-container p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 10px 0;
}

/* Footer Section */
.about-us-footer {
  margin-top: 30px;
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-us-header h1 {
      font-size: 2rem;
  }

  .context-container {
      padding: 15px 20px;
  }

  .context-container p {
      font-size: 1rem;
  }
}
