.dashboard-content-organization-management .title {
    font-size: large;
    font-weight: 500;
    margin-bottom: 15px;
}

.organization-admin-save-button{
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
    margin-right: 10px;
}

.organization-admin-save-button:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}

.organization-admin-cancel-button{
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.organization-admin-cancel-button:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
}