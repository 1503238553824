.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.signup-box {
  width: 400px;
  padding: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.company-logo {
  width: 300px;
}

.ant-form-item {
  margin-bottom: 16px;
}

.signup-button {
  margin-top: 20px;
  width: 100%;
}

.signup-container .ant-btn {
  background-color: #00b080 !important;
  height: 40px;
}

.signup-container .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00b080 !important;
  border-color: #00b080 !important;
}

.google-signup-button {
  color: white;
  width: 100%;
}

.signup-container .ant-form-item-explain-error{
  margin-top: 5px;
  margin-bottom: 5px;
}