.dashboard-content-request-management .title {
    font-size: large;
    font-weight: 500;
    margin-bottom: 15px;
}

.edit-ok-button {
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.edit-ok-button:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}

.edit-cancel-button {
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.edit-cancel-button:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
}

.add-grant-button {
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.add-grant-button:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}

.add-grant-cancel {
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.add-grant-cancel:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
}

.grant-modal-card .ant-card-body{
    padding: 0px !important;
}

.grant-modal-card .ant-btn{
    color: #00b080 !important;
}

.grant-modal-card .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #00b080 !important;
    border-color: #00b080 !important;
}

.grants-ok-button{
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.grants-ok-button:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}

.grants-cancel-button{
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.grants-cancel-button:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
}