.custom-collapse .ant-collapse-content-box {
    padding: 0 !important;
}

.subscription-card {
    margin-bottom: 80px;
}

.account-save-button {
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.change-password-button {
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.update-organization-button {
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
    width: 100%;
}

.ant-select-selector {
    height: auto !important;
    min-height: 40px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    padding: 2px 8px !important;
    /* adjust padding as needed */
    overflow: visible !important;
    /* allow container to expand */
}

.ant-select-selection-overflow {
    overflow: visible !important;
}

/* Optional: add spacing between tags */
.ant-select-selection-item {
    margin: 4px 4px 0 0 !important;
}

.ant-card-body {
    overflow: visible !important;
    height: auto !important;
}