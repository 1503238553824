@font-face {
  font-family: 'BasiqueRegular';
  src: url('../assets/fonts/BasiqueRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.header {
  background-color: #ffffff;
  margin-top: 10px;
  padding: 0 200px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  flex: 1;
  text-align: center;
  padding-top: 45px;
}

.logo img {
  height: 50px;
  width: auto;
}

.nav-menu {
  flex: 2;
  display: flex;
  justify-content: center;
  border-bottom: none;
  margin-top: 20px;
}

.nav-menu .ant-menu-item {
  font-weight: 500;
  font-size: 20px !important;
  color: rgb(52, 52, 52) !important;
  font-family: 'BasiqueRegular', sans-serif;
}

.nav-menu .ant-menu-item-selected {
  color: rgb(0, 176, 128) !important;
}

.nav-menu .ant-menu-item::after {
  border-bottom: none !important;
}

.nav-menu .ant-menu-item:hover {
  color: rgb(0, 176, 128) !important;
}

.buttons {
  flex: 1;
  text-align: right;
  margin-top: 20px;
}

.buttons .ant-btn {
  margin-left: 10px;
}

.sign-up {
  background: #00b080 !important;
  border-color: #00b080 !important;
  color: white;
  font-weight: 500;
  font-size: 12pt;
  padding: 0 20px;
  height: 40px;
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.sign-up:hover {
  border-color: rgb(0, 215, 158) !important;
  background-color: rgb(0, 215, 158) !important;
}

.sign-in {
  background: white !important;
  border-color: #00b080 !important;
  color: #00b080;
  font-weight: 500;
  font-size: 12pt;
  padding: 0 20px;
  height: 40px;
  border-radius: 15px;
}

.sign-in:hover {
  color: #0edaa3 !important;
  border-color: #0edaa3 !important;
}

.menu-button {
  font-size: 24px;
}

.nav-drawer .ant-drawer-body {
  padding: 0;
}

.drawer-buttons {
  padding: 16px;
}

.drawer-buttons .ant-btn {
  margin-bottom: 10px;
}

.nav-menu .ant-menu-item,
.nav-drawer .ant-menu-item {
  font-weight: 500;
  font-size: 16px;
  color: rgb(52, 52, 52) !important;
  font-family: 'BasiqueRegular', sans-serif;
}

.nav-menu .ant-menu-item-selected,
.nav-drawer .ant-menu-item-selected {
  color: rgb(0, 176, 128) !important;
  background-color: transparent !important;
}

.nav-menu .ant-menu-item::after,
.nav-drawer .ant-menu-item::after {
  border-bottom: none !important;
}

.nav-menu .ant-menu-item:hover,
.nav-drawer .ant-menu-item:hover {
  color: rgb(0, 176, 128) !important;
}

@media (max-width: 1230px) {
  .header {
    padding: 0 10px;
  }
}

@media (max-width: 850px) {
  .header {
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .header {
    height: 64px;
    padding: 0 10px;
  }

  .menu-button {
    display: inline-block;
    order: 1;
  }

  .logo {
    order: 2;
    flex: 1;
    text-align: center;
    padding-top: 45px;
  }

  .logo img {
    height: 40px;
    padding-right: 30px;
  }

  .buttons {
    display: none;
  }

  .nav-menu {
    display: none;
  }
}