.verification-sent-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
}

.verification-sent-container .ant-result{
    margin-bottom: 50px;
}

.verification-sent-container .ant-result-icon svg{
    color: #00b080;
}