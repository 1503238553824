.dashboard-content-notification-management {
    background: #fff;
    border-radius: 8px;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.search-filter-section{
    margin-bottom: 15px;
}

.search-filter-section .title {
    font-size: large;
    font-weight: 500;
}

.ant-switch-checked{
    background-color: #00b080 !important;
}

.cancel-notification-button{
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.cancel-notification-button:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
}

.send-notification-button{
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.send-notification-button:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}
