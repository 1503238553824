.dashboard-content-user-management .title {
    font-size: large;
    font-weight: 500;
    margin-bottom: 15px;
}

.user-management-edit-button {
    background: #00b080 !important;
    border-color: #00b080 !important;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.user-management-edit-button:hover {
    border-color: #0edaa3 !important;
    background-color: #0edaa3 !important;
}

.user-management-cancel-button{
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
}

.user-management-cancel-button:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00b080;
    border-color: #00b080;
}

.ant-checkbox-inner:hover{
    background-color: #0edaa3 !important;
}

/* Regular hover for enabled checkboxes */
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    background-color: #0edaa3 !important;
  }
  
  /* Force disabled checkbox to maintain its disabled color on hover */
  .ant-checkbox-wrapper.ant-checkbox-disabled:hover .ant-checkbox-inner {
    background-color: #b1b1b1 !important;
    border-color: #b1b1b1 !important;
  }

.ant-checkbox-checked:after { border: none !important }

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #b1b1b1;
    border-color: #b1b1b1;
}