.dashboard-content-grants {
    padding: 24px;
}

.custom-card {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.custom-card p {
    margin: 0;
}

.dashboard-content-grants .ant-card-head {
    font-size: 20px;
}

.dashboard-content-grants .ant-card-body {
    
    flex-direction: column;
    justify-content: space-between;
    height:160px;
}

.dashboard-content-grants .ant-pagination-item-active {
    border-color: rgb(0, 176, 128) !important;
}

.dashboard-content-grants .ant-pagination-item-active a {
    color: rgb(0, 176, 128) !important;
}

.dashboard-content-grants .anticon-double-right{
    color: rgb(0, 176, 128) !important;
}

.dashboard-content-grants .anticon-double-left{
    color: rgb(0, 176, 128) !important;
}



.dashboard-content-grants .ant-card-head {
    background-color: rgb(0, 176, 128);
    color: white;
}

.dashboard-content-grants .title{
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: #44534f;
}

