@font-face {
  font-family: 'BasiqueRegulare';
  src: url('../../assets/fonts/BasiqueRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.pricing-container {
  font-family: 'BasiqueRegular', sans-serif !important;
  color: #555;
  letter-spacing: 2px;
}

.pricing-container .ant-btn {
  background: white !important;
  border-color: #00b080 !important;
  color: #00b080;
  font-weight: 500;
  font-size: 12pt;
  padding: 0 20px;
  height: 40px;
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.pricing-container .ant-btn:hover {
  border-color: #0edaa3 !important;
  color: #0edaa3 !important;
}

.pricing-container .ant-card {
  font-family: 'BasiqueRegular', sans-serif !important;
  letter-spacing: 0.6px;
}