.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
  }
  
  .reset-password-card {
    width: 700px;
    padding: 30px;
    margin-bottom: 50px;
    box-shadow: none !important;
  }
  
  .reset-password-title {
    text-align: left;
    margin-bottom: 36px;
    font-family: 'Basique', sans-serif;
  }
  
  .reset-password-button {
    background: white !important;
    border-color: #00b080 !important;
    color: #00b080;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
  }
  
  .reset-password-button:hover {
    color: #0edaa3 !important;
    border-color: #0edaa3 !important;
  }
  
  .reset-password-input {
    height: 50px;
  }
  
  .reset-password-container .ant-form-item-explain-error {
    margin-bottom: 15px !important;
  }
  