.upgrade-button {
    background-color: #5421d3;
    border-color: #5421d3;
    color: white;
    font-weight: 500;
    font-size: 12pt;
    padding: 0 20px;
    height: 40px;
    border-radius: 15px;
    width: 100%;
}

.title-component {
    text-align: right;
    margin-bottom: 8px;
    margin-top: 32px;
    color: #000;
    font-weight: 350 !important;
}

