@font-face {
    font-family: 'Basique';
    src: url('../../assets/fonts/BasiqueBold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

.home-container {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
padding: 0px;
font-family: 'Basique', sans-serif;
}

.home-header {
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  text-align: center;
}

.home-header h3 {
  color: #44534f;
  margin: 0;
  font-size: 1.5rem;
}

.home-header h1 {
font-size: 4rem;
color: #00b080;
margin-top: 0px;
}

.home-header .ant-btn{
    font-family: 'BasiqueRegular', sans-serif !important;
    border: 0 !important;
    box-shadow: none !important;
}

.learn-more h4 {
    margin: 0;
    color: #44534f;
  }
  
  .learn-more .anticon {
    color: #44534f;
    padding: 0 80px !important;
  }
  
  .learn-more:hover h4,
  .learn-more:hover .anticon {
    color: #69807a;
    cursor: pointer;
  }

  .more-info {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .discover-faster {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #00b080;
    overflow-x: hidden;
  }

  .discover-faster h2 {
    text-align: center;
    font-size: 2.5rem;
    color: white;
    margin-bottom: 20px;
  }
  
  .discover-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .discover-icon {
    width: 400px;
    height: auto;
    color: white;
  }
  
  
  .discover-text-container p {
    font-size: 1.7rem;
    color: white;
    max-width: 600px;
    margin-left: 20px;
    line-height: 1.5;
    /* text-align: left; */
    margin: 0;
    /* margin-left: 50px; */
    padding: 20px;
  }

  .df-learn-more{
    margin-bottom: 30px;
  }

  .df-learn-more h4 {
    margin: 0;
    color: white;
  }
  
  .df-learn-more .anticon {
    color: white;
    padding: 0 80px !important;
  }
  
  .df-learn-more:hover h4,
  .df-learn-more:hover .anticon {
    color: #f3f3f3;
    cursor: pointer;
  }

  .ai-support{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
  }

  .ai-support h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #555;
    margin-bottom: 20px;
  }

  .ai-content{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ai-icon-container {
    /* width: 400px;
    height: auto;
    color: #555; */
  }

  .ai-text-container {
    font-size: 1.7rem;
    color: #555;
    max-width: 600px;
    margin-left: 20px;
    line-height: 1.5;
    /* text-align: left; */
    margin: 0;
    /* margin-left: 50px; */
    padding: 20px;
  }

  .ai-learn-more{
    margin-bottom: 30px;
  }

  .ai-learn-more h4 {
    margin: 0;
    color: #555;
  }
  
  .ai-learn-more .anticon {
    color: #555;
    padding: 0 80px !important;
  }
  
  .ai-learn-more:hover h4,
  .ai-learn-more:hover .anticon {
    color: #7e7e7e;
    cursor: pointer;
  }

  .tailored {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #00b080;
    overflow-x: hidden;
  }

  .tailored h2 {
    text-align: center;
    font-size: 2.5rem;
    color: white;
    margin-bottom: 20px;
  }
  
  .tailored-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .discover-icon {
    width: 400px;
    height: auto;
    color: white;
  }
  
  
  .tailored-text-container p {
    font-size: 1.7rem;
    color: white;
    max-width: 600px;
    margin-left: 20px;
    line-height: 1.5;
    /* text-align: left; */
    margin: 0;
    /* margin-left: 50px; */
    padding: 20px;
  }

  .tailored-learn-more{
    margin-bottom: 30px;
  }

  .tailored-learn-more h4 {
    margin: 0;
    color: white;
  }
  
  .tailored-learn-more .anticon {
    color: white;
    padding: 0 80px !important;
  }
  
  .tailored-learn-more:hover h4,
  .tailored-learn-more:hover .anticon {
    color: #f3f3f3;
    cursor: pointer;
  }



  
  