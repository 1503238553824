/* Footer Container */
.footer {
    background-color: #ffffff;
    color: #333;
    padding: 20px 20px;
    text-align: center;
    font-family: 'Basique', sans-serif;
}

/* Footer Content Layout */
.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Footer Sections */
.footer-section {
    flex: 1;
    min-width: 250px;
    margin: 10px;
}

.footer-section h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.footer-section p,
.footer-section ul {
    font-size: 0.9rem;
    margin: 5px 0;
    line-height: 1.6;
}

.footer-section a {
    color: #333;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}

/* Social Icons */
.social-icons a {
    display: inline-block;
    margin-right: 15px;
    font-size: 0.9rem;
}

/* Footer Bottom */
.footer-bottom {
    margin-top: 30px;
    font-size: 0.8rem;
    color: #333;
}

.footer-section ul {
    list-style-type: none; /* Removes bullets */
    padding: 0; /* Removes default padding */
    margin: 0; /* Removes default margin */
    display: flex; /* Makes the list horizontal */
    justify-content: center; /* Centers the links horizontally */
    gap: 20px; /* Adds space between links */
}

.footer-section ul li {
    margin: 0; /* Removes vertical spacing since links are now horizontal */
}

.footer-section ul li a {
    color: #333; /* Ensures the text color matches the theme */
    text-decoration: none; /* Removes underline */
    transition: color 0.3s ease; /* Adds a smooth transition effect */
}

.footer-section ul li a:hover {
    text-decoration: underline; /* Adds underline on hover */
    color: #333; /* Changes color on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
    }
}
