.add-organization-container{
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: white;
}

.organization-box{
    width: 400px;
    padding: 40px;
    border-radius: 8px;
}

.next-button{
    width: 100%;
}

.add-organization-container .ant-btn {
    background-color: #00b080 !important;
    height: 40px;
}

.add-organization-container .ant-select{
    width: 100% !important;
}

