.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}


.content-area {
    display: flex;
    flex: 1;
    height: 100vh;
    overflow: hidden;
}

.main-content {
    flex: 1;
    padding: 1.5rem;
    background-color: #fff;
    overflow-y: auto;
    height: 100%;
}