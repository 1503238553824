.dashboard-content-main-container {
    display: flex;
    justify-content: space-between;
    padding: 24px;
}

.content-container {
    flex: 3;
    margin-right: 16px;
    background-color: transparent;
    padding: 16px;
    border-radius: 8px;
}

.content-container-title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
    color: #44534f;
}

.profile-container {
    flex: 1;
    background-color: transparent;
    padding: 16px;
    border-radius: 8px;
    margin-top: 41px;
}

.profile-title {
    font-weight: 600;
    font-size: 15px;
    color: #44534f;
    text-align: center;
}

.request-button{
    text-align: center;
    margin-top: 30px;
}

.profile-container .ant-btn {
    background-color: rgb(0, 176, 128);
    border-color: rgb(0, 176, 128);
    font-weight: 500;
    box-shadow: none !important;
}

.profile-container .ant-btn:hover {
    border-color: rgb(0, 215, 158) !important;
    background-color: rgb(0, 215, 158) !important;
}