.dashboard-header {
    background-color: #fff;
    padding: 0 50px;
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
}

.dashboard-logo img {
    height: 40px;
    margin-top: 30px;
    width: auto;
}

.dashboard-nav-user-info{
    flex: 1;
    text-align: right;
}

.dashboard-nav-user-name-surname{
    margin-left: 20px;
}

.dashboard-header .anticon-user {
    font-size: 25px !important;
    color: #44534f;
}