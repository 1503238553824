.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.signin-box {
    width: 400px;
    padding: 40px;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.company-logo {
    width: 300px;
}

.ant-form-item {
    margin-bottom: 16px;
}

.signin-button {
    margin-top: 20px;
    width: 100%;
}

.signin-container .ant-btn {
    background-color: #00b080 !important;
    height: 40px;
}

.signin-container .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00b080 !important;
    border-color: #00b080 !important;
}

.forgot-password {
    color: #00b080;
}

.google-signin-button {
    color: white;
    width: 100%;
}

.forgot-password:hover {
    color: #0edaa3 !important;
    cursor: pointer;
}

