.dashboard-content-notification-user {
    max-width: 900px; /* Center content */
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 50px; /* Add space at the bottom */
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.notification-list {
    display: flex;
    flex-direction: column;
    gap:5px;
}

.notification-card {
    width: 100%;
    border-radius: 0px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    cursor: pointer;
}

/* Read Notifications (Gray background) */
.notification-card.read {
    background-color: #f5f5f5;
    color: #888;
}

/* Unread Notifications (White background, bold text) */
.notification-card.unread {
    background-color: white;
    font-weight: bold;
}

/* Hover Effect */
.notification-card:hover {
    transform: scale(1.01);
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Flexbox to align text and delete icon */
.notification-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Delete Icon */
.delete-icon {
    font-size: 18px;
    color: #ff4d4f; /* Red color */
    cursor: pointer;
}

/* Delete Icon Hover Effect */
.delete-icon:hover {
    color: darkred;
}


.notification-card h3 {
    margin-bottom: 8px;
    
}

.notification-message {
    font-size: 20px;
    margin-bottom: 10px;
    
}

.notification-title{
    font-size: 25px;
    margin-bottom: 10px;
    
}

